import { useEffect, useState } from "react";


const isDev = (import.meta.env.VITE_APP_MODE || "prod").trim().toLowerCase() === "dev";
const currentVersion = (import.meta.env.VITE_APP_VERSION || "1.0.0").trim();

let timeoutSeconds = import.meta.env.VITE_APP_VERSION_CHECK_TIMEOUT || 60; // Default to 60 seconds if not set
if (typeof timeoutSeconds === "string") {
  timeoutSeconds = parseInt(timeoutSeconds);
}
const checkVersionInterval = timeoutSeconds * 1000; // Convert seconds to milliseconds


const getVersionString = (version?: string) => {
  if (version?.trim()?.match(/^[0-9]/)) {
    return version!.trim();
  } else {
    return currentVersion;
  }
}


const useCheckVersion = () => {
  const [isNewVersionAvailable, setIsNewVersionAvailable] = useState(false);

  useEffect(() => {
    const checkVersion = async () => {
      let stateUpdated = false;
      try {
        const response = await fetch('/version');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        // Compare fetched version with VITE_APP_VERSION
        const version = getVersionString(await response.text());
        if (version?.trim() !== currentVersion?.trim()) {
          // Notify user here
          setIsNewVersionAvailable(true);
          stateUpdated = true;
        }
      } catch (error) {
        console.error('There has been a problem with your fetch operation:', error);
      } finally {
        if (!stateUpdated) window.setTimeout(checkVersion, checkVersionInterval);
      }
    };

    if (!isDev) checkVersion();
  }, []);

  return isNewVersionAvailable;
}

export default useCheckVersion;
