import { createApi } from "@reduxjs/toolkit/query/react";
import { baseAppQuery } from "./baseQuery";
import { SignatureDocument } from "types/signatures";
import { ContractData, ContractInfo, ContractFilter } from "types/contracts";
import { formatDateUi } from "utils/converters";

const ENDPOINT = "contract";
const contractsApi: any = createApi({
  reducerPath: "contractsApi",
  baseQuery: baseAppQuery,
  keepUnusedDataFor: 0,
  refetchOnMountOrArgChange: true,
  tagTypes: ["contracts"],
  endpoints: (builder) => ({
    getContractList: builder.query<ContractData, ContractFilter>({
      query: (filter) => {
        const queryParams = new URLSearchParams();
        if (filter) {
          queryParams.set("page", filter.pageNumber.toString());
          queryParams.set("size", filter.pageSize.toString());

          if (filter.orgId) queryParams.set("orgId", filter.orgId);

          if (filter.dateFrom) {
            queryParams.set("fromDate", formatDateUi(filter.dateFrom));
          }
          if (filter.dateTo) {
            queryParams.set("toDate", formatDateUi(filter.dateTo));
          }
          if (filter.isSign === "signed") queryParams.set("isSign", "true");
          if (filter.isSign === "not_signed") {
            queryParams.set("isSign", "false");
          }
        }
        return `${ENDPOINT}/fetch-all?${queryParams}`;
      },
    }),
    getContractListCurrent: builder.query<ContractData, ContractFilter>({
      query: (filter) => {
        const queryParams = new URLSearchParams();
        if (filter) {
          queryParams.set("page", filter.pageNumber.toString());
          queryParams.set("size", filter.pageSize.toString());
          if (filter.orgId) queryParams.set("orgId", filter.orgId);
          if (filter.isSign) queryParams.set("isSign", String(filter.isSign));
          if (filter.dateFrom) {
            queryParams.set("fromDate", formatDateUi(filter.dateFrom));
          }
          if (filter.dateTo) {
            queryParams.set("toDate", formatDateUi(filter.dateTo));
          }
        }
        return `${ENDPOINT}/fetch-all-current?${queryParams}`;
      },
    }),
    getContractDocument: builder.query<SignatureDocument, string>({
      query: (id) => `${ENDPOINT}/document/${id}`,
    }),
    getContractFindByApplicationId: builder.query<ContractInfo, string>({
      query: (id) => `${ENDPOINT}/find-by-application?applicationId=${id}`,
    }),
    revokeContract: builder.mutation<
      ContractData,
      { applicationId: string; comment: string }
    >({
      query: ({ applicationId, comment }) => ({
        url: `${ENDPOINT}/revoke/${applicationId}?comment=${encodeURIComponent(comment)}`,
        method: "POST",
      }),
    }),
    revokeContractByApplicationId: builder.mutation<ContractData, {applicationId:string, comment: string}>({
      query:(data)=>({
        url:  `${ENDPOINT}/revoke`,
        method: "POST",
        body: data,
      }), 
    })
  }),
});

export const {
  useGetContractListQuery,
  useGetContractListCurrentQuery,
  useGetContractDocumentQuery,
  useLazyGetContractDocumentQuery,
  useGetContractFindByApplicationIdQuery,
  useRevokeContractMutation,
  useRevokeContractByApplicationIdMutation
} = contractsApi;
export default contractsApi;
