import { createApi } from "@reduxjs/toolkit/query/react";
import { baseAppQuery } from "./baseQuery";
import { Filter } from "types/filters";
import {
  EnclosureData,
  AttachmentInfo,
  AttachmentField,
  AttachmentFieldValue,
} from "types/contracts";
import { UploadedFile } from "types/dropzone";
import { StatusHistory } from "types/statuses";

const enclosuresApi: any = createApi({
  reducerPath: "enclosuresApi",
  baseQuery: baseAppQuery,
  keepUnusedDataFor: 0,
  refetchOnMountOrArgChange: true,
  tagTypes: ["enclosures", "enclosureAttachments"],
  endpoints: (builder) => ({
    getEnclosureList: builder.query<
      EnclosureData,
      {
        applicationId?: string;
        filter: Filter;
        type: "notice" | "registry";
        orgId: string;
      }
    >({
      query: ({ applicationId, filter, type, orgId }) => {
        const queryParams = new URLSearchParams();
        if (applicationId) queryParams.set("applicationId", applicationId);
        if (filter) {
          queryParams.set("page", filter.pageNumber.toString());
          queryParams.set("size", filter.pageSize.toString());
          if (orgId) queryParams.set("orgId", orgId);
        }
        return `${type}/fetch-all?${queryParams}`;
      },
      providesTags: ["enclosures", "enclosureAttachments"],
    }),
    getEnclosureById: builder.query<
      EnclosureData,
      { id: string; type: "notice" | "registry" }
    >({
      query: ({ id, type }) => `${type}/${id}`,
    }),
    getEnclosureListCurrent: builder.query<
      EnclosureData,
      { applicationId: string; filter: Filter; type: "notice" | "registry" }
    >({
      query: ({ applicationId, filter, type }) => {
        const queryParams = new URLSearchParams();
        if (filter) {
          queryParams.set("page", filter.pageNumber.toString());
          queryParams.set("size", filter.pageSize.toString());
        }
        return `${type}/fetch-all-current?applicationId=${applicationId}&${queryParams}`;
      },
      providesTags: ["enclosures", "enclosureAttachments"],
    }),
    getEnclosureByIdCurrent: builder.query<
      EnclosureData,
      { id: string; type: "notice" | "registry" }
    >({
      query: ({ id, type }) => `${type}/current/${id}`,
    }),
    getEnclosureHistoryById: builder.query<
      StatusHistory[],
      { id: string; type: "notice" | "registry" }
    >({
      query: ({ id, type }) => `${type}/status-history/${id}`,
    }),
    getEnclosureAttachmentById: builder.query<
      AttachmentInfo,
      { id: string; type: "notice" | "registry" }
    >({
      query: ({ id, type }) => `${type}/fetch-attachment/${id}`,
      providesTags: ["enclosureAttachments"],
    }),
    getEnclosureDocumentById: builder.query<
      UploadedFile,
      { id: string; type: "notice" | "registry" }
    >({
      query: ({ id, type }) => `${type}/document/${id}`,
    }),
    createEnclosure: builder.mutation<
      EnclosureData,
      { applicationId: string; type: "notice" | "registry" }
    >({
      query: ({ applicationId, type }) => ({
        url: `${type}?applicationId=${applicationId}`,
        method: "POST",
      }),
      invalidatesTags: ["enclosures"],
    }),
    revisionEnclosure: builder.mutation<
      EnclosureData,
      { enclosureId: string; comment: string; type: "notice" | "registry" }
    >({
      query: ({ enclosureId, comment, type }) => {
        return {
          url: `${type}/revision`,
          method: "POST",
          body: { [`${type}Id`]: enclosureId, comment },
        };
      },
      invalidatesTags: ["enclosures", "enclosureAttachments"],
    }),
    addEnclosureAttachment: builder.mutation<
      AttachmentInfo,
      { id: string; type: "notice" | "registry" }
    >({
      query: ({ id, type }) => ({
        url: `${type}/attachment-add?${type}Id=${id}`,
        method: "POST",
      }),
      invalidatesTags: ["enclosureAttachments"],
    }),
    updateEnclosureAttachment: builder.mutation<
      AttachmentInfo,
      { data: AttachmentField; type: "notice" | "registry" }
    >({
      query: ({ data, type }) => {
        let formData = new FormData();
        const attachment = { ...data } as AttachmentFieldValue;
        Object.keys(attachment).forEach((key: string) => {
          if (key !== "documents") formData.append(key, attachment[key]);
        });
        if (data?.documents?.length) {
          const files = [...data.documents] as unknown as File[];
          files.forEach((file) => {
            formData.append("files", file);
          });
        }
        return {
          url: `${type}/attachment-update`,
          method: "PUT",
          body: formData,
        };
      },
      invalidatesTags: ["enclosureAttachments"],
    }),
    deleteEnclosureAttachment: builder.mutation<
      AttachmentInfo,
      { enclosureId: string; attachmentId: string; type: "notice" | "registry" }
    >({
      query: ({ enclosureId, attachmentId, type }) => ({
        url: `${type}/attachment-delete?attachmentId=${attachmentId}&${type}Id=${enclosureId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["enclosureAttachments"],
    }),
  }),
});

export const {
  useGetEnclosureListQuery,
  useGetEnclosureByIdQuery,
  useGetEnclosureListCurrentQuery,
  useGetEnclosureByIdCurrentQuery,
  useGetEnclosureHistoryByIdQuery,
  useGetEnclosureAttachmentByIdQuery,
  useGetEnclosureDocumentByIdQuery,
  useLazyGetEnclosureDocumentByIdQuery,
  useCreateEnclosureMutation,
  useRevisionEnclosureMutation,
  useAddEnclosureAttachmentMutation,
  useUpdateEnclosureAttachmentMutation,
  useDeleteEnclosureAttachmentMutation,
} = enclosuresApi;
export default enclosuresApi;
