// types
import {
  ConfigProps,
  MenuOrientation,
  ThemeDirection,
  ThemeMode,
} from "types/config";

// export const DASHBOARD_PATH = getInitialPage();
export const HORIZONTAL_MAX_ITEM = 7;

const config: ConfigProps = {
  menuOrientation: MenuOrientation.VERTICAL,
  miniDrawer: false,
  fontFamily: `'Roboto', sans-serif`,
  borderRadius: 8,
  outlinedFilled: true,
  mode: ThemeMode.LIGHT,
  presetColor: "finq",
  i18n: "ru",
  themeDirection: ThemeDirection.LTR,
  container: false,
};

export default config;
